<template>
  <div class="mt-5">
    <div class="d-flex align-center justify-center" v-show="loading" />
    <v-container v-show="!loading">
      <h1 class="text-h3">Edit photos</h1>
      <p class="text-h5">{{ restaurant.name }}</p>

      <PhotoManager :restaurant-id="restaurantId" />
    </v-container>
  </div>
</template>

<script>
import PhotoManager from '@/components/PhotoManager/PhotoManager'

import firebase from '@/plugins/firebase'

export default {
  name: 'GalleryEditor',

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  },

  components: {
    PhotoManager
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    collectionPath () {
      return `/restaurants/${this.restaurantId}/photos`
    },
    storagePath () {
      return `/users/${this.user.uid}/restaurants/${this.restaurantId}`
    }
  },

  data () {
    return ({
      loading: false,
      restaurant: {},
      selected: []
    })
  },

  methods: {
    getRestaurantData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/restaurants/${this.restaurantId}`).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    this.$data.restaurant = await this.getRestaurantData()
    this.$data.loading = false
  }
}
</script>
