<template>
  <v-dialog v-model="dialogOpen">
    <template #activator="slotProps">
      <slot name="activator" v-bind="slotProps" :disabled="!targets.length">
        <v-btn v-on="slotProps.on" :disabled="!targets.length">Delete</v-btn>
      </slot>
    </template>

    <v-overlay v-if="loading">
      <v-progress-circular indeterminate class="accent" />
    </v-overlay>
    <v-card v-else>
      <v-card-title>Are you sure you want to delete {{ targets.length }} photo{{ targets.length > 1 ? 's' : '' }}?</v-card-title>
      <v-card-actions class="justify-end"><v-btn color="primary" text @click="cancel">Cancel</v-btn><v-btn text @click="execute" color="primary">OK</v-btn></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from '@firebase/app'
export default {
  name: 'ImageArrayTerminator',

  props: {
    targets: {
      type: Array,
      required: true,
      default: () => {}
    }
  },

  computed: {
    deleteTasks () {
      return this.targets.map(t => {
        return new Promise((resolve, reject) => {
          firebase.firestore().doc(t).delete()
            .then(() => { resolve() })
            .catch(e => { reject(e) })
        })
      })
    }
  },

  data () {
    return ({
      dialogOpen: false,
      loading: false
    })
  },

  methods: {
    cancel () {
      this.$data.dialogOpen = false
    },
    async execute () {
      this.$data.loading = true
      try {
        await Promise.all(this.deleteTasks())
      } catch (e) {
        this.$emit('error', e)
      }
      this.$data.loading = false
      this.$data.dialogOpen = false
    }
  }
}
</script>
