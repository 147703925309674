<template>
  <v-dialog v-model="dialogOpen">
    <template #activator="slotProps">
      <slot name="activator" v-bind="slotProps">
        <v-btn v-on="slotProps.on">Upload New</v-btn>
      </slot>
    </template>
    <v-card>
      <v-form @submit.prevent="handleSubmit" @reset="dialogOpen = false">
        <v-card-title>Select{{!multiple ? ' an ' : ' ' }}image</v-card-title>
        <v-card-text>
          <v-file-input :chips="multiple" :label="`Select file${multiple ? 's' : ''}`" :loading="loading" :disabled="loading" v-model="files" prepend-icon="" :multiple="multiple" prepend-inner-icon="mdi-image" />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn :disabled="loading" color="primary" text type="reset">Cancel</v-btn>
          <v-btn :disabled="loading || !files.length" color="primary" type="submit" text>Upload</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'FirebaseImageUploaderDialog',

  props: {
    'storage-path': {
      default: () => '/'
    },
    multiple: {
      default: () => false,
      type: Boolean
    }
  },

  data () {
    return ({
      dialogOpen: false,
      loading: false,
      files: []
    })
  },

  methods: {
    uploadImage (file) {
      return new Promise((resolve, reject) => {
        var storageLocation = firebase.storage().ref(this.storagePath).child((new Date()).toString())
        var fileRef = storageLocation.child(file.name)

        fileRef.put(file)
          .then(uploadTaskSnapshot => { resolve(uploadTaskSnapshot.ref) })
          .catch(e => { reject(e) })
      })
    },

    async getImageURL (fileRef) {
      return await fileRef.getDownloadURL()
    },

    async handleSubmit () {
      this.$data.loading = true
      var uploadPromises = this.$data.files.map(file => (
        new Promise((resolve, reject) => {
          this.uploadImage(file)
            .then(ref => this.getImageURL(ref))
            .then(url => { resolve(url) })
            .catch(e => { reject(e) })
        })
      ))

      try {
        var urls = await Promise.all(uploadPromises)
        var result = this.multiple ? urls : urls[0]
        this.$emit('input', result)
      } catch (e) {
        this.$emit('error', e)
      }

      this.$data.files = []
      this.$data.loading = false
      this.$data.dialogOpen = false
    }
  }
}
</script>
