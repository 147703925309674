<template>
  <v-item-group multiple v-model="selected">
    <v-container fluid class="ma-0 pa-0">
      <!-- <v-row v-if="!photos.length" align="center" justify="center">
        <v-progress-circular indeterminate />
      </v-row> -->
      <v-row>
        <v-col cols="4" xs="6" sm="6" md="4" lg="3" xl="2" v-for="photo in photos" :key="photo.id">
          <v-item :value="photo.id" v-slot="{ active, toggle }">
            <v-dialog>
              <template #activator="{ on }">
                <v-card v-on="on">
                  <v-img aspect-ratio="1" :src="photo.publicUrl">
                    <div class="d-flex align-top justify-start">
                      <v-btn elevation="3" large @click.stop="toggle" icon color="white"><v-icon large>{{ !active ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked'}}</v-icon></v-btn>
                    </div>
                  </v-img>
                </v-card>
              </template>
              <v-img :src="photo.publicUrl" class="align-end">
                <div class="white--text pa-1" style="background-color: rgba(0, 0, 0, 0.5)">
                  Uploaded {{ formatDate(photo.upload) }}
                </div>
              </v-img>
            </v-dialog>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import firebase from '@/plugins/firebase'
import moment from 'moment'

export default {
  name: 'GallerySelector',

  props: {
    value: {
      type: Array,
      default: () => []
    },

    'collection-path': {
      type: String,
      required: true
    }
  },

  computed: {
    selected: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },

  data () {
    return ({
      photos: [],
      listener: null
    })
  },

  methods: {
    handleUpdate (change) {
      var doc = change.doc
      var index = this.$data.photos.findIndex(e => e.id === doc.id)
      if (change.type === 'added') {
        this.$data.photos.unshift(Object.assign({ id: doc.id }, doc.data()))
      }
      if (change.type === 'modified') {
        this.$data.photos.splice(index, 1, Object.assign({ id: doc.id }, doc.data()))
      }
      if (change.type === 'removed') {
        this.$data.photos.splice(index, 1)
      }
    },

    formatDate (date) {
      return moment(date).format('yyyy-MM-DD HH:mm')
    },

    listenForChanges () {
      return firebase.firestore().collection(this.collectionPath).onSnapshot(snapshot => {
        snapshot.docChanges().forEach(this.handleUpdate)
      })
    }
  },

  mounted () {
    this.$data.listener = this.listenForChanges()
  },

  beforeRouteLeave () {
    this.$data.listener.unsubscribe()
  }
}
</script>
