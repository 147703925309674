<template>
  <div>
    <div class="mb-3">
      <div class="d-flex align-center">
        <FirebaseImageUploaderDialog multiple :storage-path="storagePath">
          <template #activator="{ on }">
            <v-btn class="mr-1" color="primary" large rounded v-on="on"><v-icon large left>mdi-plus</v-icon>Upload New Photo</v-btn>
          </template>
        </FirebaseImageUploaderDialog>
        <ImageDeleter :targets="deleteTargets">
          <template #activator="{ on, disabled }">
            <v-btn class="mx-1" :disabled="disabled" rounded v-on="on">
              <v-icon left>mdi-delete</v-icon>Delete {{ selected.length ? selected.length : '' }} item{{ selected.length === 1 ? '' : 's'}}
            </v-btn>
          </template>
        </ImageDeleter>
      </div>
      <div class="text--secondary font-italic">Newly uploaded pictures might take up to one minute to show up on the grid.</div>
    </div>
    <ImageSelector :collection-path="collectionPath" v-model="selected"/>
  </div>
</template>

<script>
import FirebaseImageUploaderDialog from '@/components/ImageUploader/FirebaseImageUploader'
import ImageSelector from '@/components/PhotoManager/Selector'
import ImageDeleter from '@/components/PhotoManager/MultipleImageDeleteDialog'

import firebase from '@/plugins/firebase'

export default {
  name: 'PhotoManager',

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  },

  components: {
    ImageSelector,
    FirebaseImageUploaderDialog,
    ImageDeleter
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    collectionPath () {
      return `/restaurants/${this.restaurantId}/photos`
    },
    storagePath () {
      return `/users/${this.user.uid}/restaurants/${this.restaurantId}`
    },
    deleteTargets () {
      return this.$data.selected.map(t => `${this.collectionPath}/${t}`)
    }
  },

  data () {
    return ({
      loading: false,
      restaurant: {},
      selected: []
    })
  },

  methods: {
    getRestaurantData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/restaurants/${this.restaurantId}`).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    this.$data.restaurant = await this.getRestaurantData()
    this.$data.loading = false
  }
}
</script>
